import MarkdownIt from 'markdown-it';

let md = new MarkdownIt({html: true, typographer: true});

// Configure Markdown links to open in new tab.
// See here: https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
let defaultRender =
  md.renderer.rules.link_open || ((tokens, index, options, env, self) => self.renderToken(tokens, index, options));

md.renderer.rules.link_open = (tokens, index, options, env, self) => {
  let attributeIndex = tokens[index].attrIndex('target');
  attributeIndex < 0 ? tokens[index].attrPush(['target', '_blank']) : tokens[index].attrs[attributeIndex][1] = '_blank';
  return defaultRender(tokens, index, options, env, self);
};

export default md;